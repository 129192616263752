
.cdd{
    text-align: center;
    font-family: "Prompt", sans-serif ;
}

.cdd-header-form{
    border-radius: 4px;
    padding: 10px;
    margin-bottom: 1.5em;
}

.cdd-session{
    border-radius: 4px;
    padding: 10px;
    margin: 2.5em 0;
}

.custom-control-input:checked ~ .custom-control-label::before{
    background-color: #688ffc !important;
}

.field-error {
    color: #fc4370;
    padding: 0 0.5em 0.5em 0.5em;
    transition: 5s;
}

.is-required{
    color: #fc4370;
padding-left: 2px;
}

h3,h4{
    font-weight: bold !important;
}

input{
    width: 100%;
    height: 42px;
    padding: 8px;
    background-color: white;
    border: 1px solid #688ffc;
    border-radius: 4px;
}
.typeahead-input-hint{
    display: none;
}
.typeahead-address-container{
    text-align: left;
}

.custom-control-label {
    font-weight: 300;
}